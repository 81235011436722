import React from "react";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { stringToSlug } from "../utils/stringManipulation";

const DestinationsRow = (props) => {
  const destination = props.destinations && props.destinations[0];

  return (
    <div className="sterea-center-column-wrapper">
      <div className="sterea-center-column py-12">
        {!props.noTitle && (
          <h2 className="text-center mb-12">Le destinazioni</h2>
        )}
        <div className="flex flex-wrap">
          {props.destinations?.map((destination) => (
            <div className="sterea-destination-card-wrapper w-full lg:w-1/2 flex px-min mb-12">
              <div className="w-full">
                <Link
                    to={`/destinazioni/${
                      (destination.Permalink &&
                        destination.Permalink != " " &&
                        destination.Permalink) ||
                      stringToSlug(destination.Titolo)
                    }`}
                    className="mt-3 flex items-center text-sterea-bright-blue"
                  >
                  <div className="sterea-destination-card">
                    <div className="color-overlay absolute w-full h-full bg-sterea-dark-blue bg-opacity-10 rounded-md z-20"></div>
                    <GatsbyImage
                      image={getImage(destination.Copertina?.localFile)}
                      style={{ position: "absolute" }}
                      className="w-full h-full z-10"
                      objectFit="cover"
                      alt={destination.Copertina?.alternativeText}
                    />
                    <h3 className="font-bold text-4xl filter drop-shadow-md text-center text-white relative z-20">
                      {destination.Titolo}
                    </h3>
                  </div>
                </Link>
                {destination.anteprima_testo && (
                  <p className="mt-3">{destination.anteprima_testo}</p>
                )}
                <Link
                  to={`/destinazioni/${
                    (destination.Permalink &&
                      destination.Permalink != " " &&
                      destination.Permalink) ||
                    stringToSlug(destination.Titolo)
                  }`}
                  className="mt-3 flex items-center text-sterea-bright-blue"
                >
                  Scopri la destinazione{" "}
                  <svg
                    className="ml-2"
                    width="29"
                    height="16"
                    viewBox="0 0 29 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28.7071 8.70711C29.0976 8.31658 29.0976 7.68342 28.7071 7.29289L22.3431 0.928932C21.9526 0.538408 21.3195 0.538408 20.9289 0.928932C20.5384 1.31946 20.5384 1.95262 20.9289 2.34315L26.5858 8L20.9289 13.6569C20.5384 14.0474 20.5384 14.6805 20.9289 15.0711C21.3195 15.4616 21.9526 15.4616 22.3431 15.0711L28.7071 8.70711ZM0 9H28V7H0V9Z"
                      fill="#00D1FF"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="flex justify-center mt-12">
      <div className="sterea-blue-purple-button">
        Vedi tutte le destinazioni
      </div>
    </div> */}
      </div>
    </div>
  );
};

export default DestinationsRow;
